<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="Inventory Transfer"
        placeholder=""
        :hasDropdown="false"
        :hasFilter="false"
        :hasSearch="false"
      />
      <div class="title-tabs mt-3">Detail</div>
      <div class="bg-white p-2 p-lg-3">
        <b-row>
          <b-col md="6" cols="12">
            <div class="d-flex">
              <p class="mt-2">
                Transfer Form <span class="text-error">*</span>
              </p>
              <InputSelect
                :disabled="form.inventory_transfer_status_id > 1"
                textField="name"
                class="flex-grow-1 ml-2"
                valueField="id"
                @input="handleSelectDocument"
                :options="transferFormList"
                v-model="form.inventory_transfer_document_id"
                :v="$v.form.inventory_transfer_document_id"
                :isValidate="$v.form.inventory_transfer_document_id.$error"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="null" disabled
                    >-- Select Transfer Form --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" class="d-flex">
            <p class="mt-2">No.</p>
            <InputText
              class="flex-grow-1 ml-2"
              placeholder="No."
              :disabled="true"
              v-model="form.document_no"
            />

            <p class="mt-2 ml-2">Created Date</p>
            <InputText
              class="flex-grow-1 ml-2"
              placeholder=""
              :disabled="true"
              v-model="form.created_time"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="6">
            Source Branch
            <b-card class="mt-2">
              <div class="d-flex mb-3">
                <AutoComplete
                  ref="sourceBranch"
                  v-model="form.source_branch_id"
                  showValue=""
                  class="flex-grow-1"
                  id="source-branch-list"
                  :disabled="
                    !form.inventory_transfer_document_id ||
                    form.inventory_transfer_status_id > 1
                  "
                  dataListID="branch-arr-list"
                  placeholder="Search Destination Branch"
                  @input="handleSelectBranch(form.source_branch_id, 0)"
                  :isRequired="true"
                  :optionsList="branchList || []"
                  :v="$v.form.source_branch_id"
                  :isValidate="$v.form.source_branch_id.$error"
                />
                <div class="icon-container ml-3 pointer">
                  <font-awesome-icon
                    icon="search"
                    class="custom-icon text-white"
                  />
                </div>
              </div>
              <div
                class="d-flex mt-2"
                v-for="item in sourceBranch"
                :key="item.label"
              >
                <div class="text-label text-light-gray f-bold">
                  {{ item.label }}
                </div>
                <div class="text-light-gray">:</div>
                <div class="">&nbsp;{{ item.value || "-" }}</div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            Destination Branch
            <b-card class="mt-2">
              <div class="d-flex">
                <AutoComplete
                  ref="destinationBranch"
                  v-model="form.destination_branch_id"
                  :disabled="
                    !form.inventory_transfer_document_id ||
                    form.inventory_transfer_status_id > 1
                  "
                  class="flex-grow-1"
                  id="Destination-branch-list"
                  dataListID="Destination-arr-list"
                  placeholder="Search Destination Branch"
                  @input="handleSelectBranch(form.destination_branch_id, 1)"
                  :isRequired="true"
                  :optionsList="branchList2 || []"
                  :v="$v.form.destination_branch_id"
                  :isValidate="$v.form.destination_branch_id.$error"
                />
                <div class="icon-container ml-3 pointer">
                  <font-awesome-icon
                    icon="search"
                    class="custom-icon text-white"
                  />
                </div>
              </div>
              <div
                class="d-flex mt-2"
                v-for="item in destinationBranch"
                :key="item.label"
              >
                <div class="text-label text-light-gray f-bold">
                  {{ item.label }}
                </div>
                <div class="text-light-gray">:</div>
                <div class="">&nbsp;{{ item.value || "-" }}</div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12">
            <InputTextArea
              textFloat="Note"
              :disabled="form.inventory_transfer_status_id > 1"
              type="text"
              class="mt-2 custom-input"
              placeholder="Note"
              rows="3"
              name="message"
              v-model="form.remark"
          /></b-col>
          <b-col cols="12">
            <div>
              <div class="title-tabs my-3">Product List</div>

              <b-row class="mb-2">
                <b-col cols="2" sm="6" md="6" class="mt-2">
                  <b-button
                    v-if="form.inventory_transfer_status_id < 2"
                    @click.prevent="selectProduct"
                    class="btn-filter mr-2"
                  >
                    <span class="d-none d-sm-flex align-items-center">
                      <span class="mr-2">Select Items </span>
                      <font-awesome-icon icon="chevron-right" class="pointer" />
                    </span>
                    <font-awesome-icon icon="chevron-right" class="d-sm-none" />
                  </b-button>
                  <span class="text-black"
                    >Selected {{ this.allItem.length }} list
                    <span class="text-error">*</span></span
                  >
                  <div v-if="$v.form.products.$error" class="text-error">
                    Please select value.
                  </div>
                </b-col>
                <b-col cols="10" sm="6" md="6" class="mt-2">
                  <b-input-group class="mb-2">
                    <b-form-input
                      type="text"
                      id="header-search-bar"
                      class="search-bar"
                      @keyup.enter="handleSearch"
                      placeholder="Search Product"
                      v-model="filter.search"
                    >
                    </b-form-input>
                    <b-input-group-append is-text>
                      <b-iconstack
                        font-scale="2"
                        type="submit"
                        @click="handleSearch"
                      >
                        <b-icon
                          stacked
                          icon="search"
                          scale="0.5"
                          variant="grey"
                        ></b-icon>
                      </b-iconstack>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(index)="{ item, index }">
                  {{ index + 1 + (filter.page - 1) * filter.take }}
                </template>
                <template v-slot:cell(serial)="{ item }">
                  {{ item.serial || "-" }}
                </template>
                <template v-slot:cell(product_image)="{ item }">
                  <div class="position-relative picture-text pic-table">
                    <div
                      v-if="item.product_image"
                      class="pic-box"
                      v-bind:style="{
                        'background-image': 'url(' + item.product_image + ')',
                      }"
                    ></div>
                    <div v-else>
                      <img
                        class="pic-box pt-0"
                        :src="default_image"
                        alt="default Image"
                        srcset=""
                      />
                    </div>
                  </div>
                </template>

                <template v-slot:cell(action)="{ item }">
                  <div v-if="form.inventory_transfer_status_id < 2">
                    <b-button
                      variant="icon"
                      class="px-0"
                      @click="deleteProduct(item.product_id)"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(quantity)="{ item, index }">
                  <div
                    class="d-flex justify-content-center align-items-center text-center"
                  >
                    <font-awesome-icon
                      v-if="form.inventory_transfer_status_id < 2"
                      class="check-circle pointer mr-1"
                      icon="minus-circle"
                      @click="handleProductQuantity(0, item)"
                    />
                    <InputText
                      placeholder="จำนวน"
                      type="number"
                      :disabled="form.inventory_transfer_status_id > 1"
                      @onKeyup.enter="handleProductQuantityInput($event, item)"
                      @blur="handleProductQuantityInput($event, item)"
                      class="mx-1 input-quantity mb-0"
                      v-model="item.quantity"
                    ></InputText>

                    <font-awesome-icon
                      v-if="form.inventory_transfer_status_id < 2"
                      class="check-circle pointer ml-1"
                      icon="plus-circle"
                      @click="handleProductQuantity(1, item)"
                    />
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                :filter="filter"
                :rows="rows"
                @pagination="pagination"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalProductList
      :document_id="form.inventory_transfer_document_id"
      ref="modalSelectProduct"
      :selectedItem="form.products"
      @success="handleSelectProduct"
    ></ModalProductList>
    <FooterAction
      @submit="checkForm(false)"
      customText="Save Draft"
      :hideSubmit="form.inventory_transfer_status_id > 1"
      @customAction="checkForm(true)"
      routePath="/inventory-transfer"
    />
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import AutoComplete from "@/components/inputs/Autocomplete";
import ModalProductList from "./components/ModalProductList.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    OtherLoading,
    ModalProductList,
    AutoComplete,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      transferFormList: [],
      sourceBranch: [
        {
          label: "Branch Name",
          key: "branch_name",
          value: "",
        },
        {
          label: "Stock",
          key: "source_branch_stock_name",
          value: "",
        },
        { label: "Code", key: "code", value: "" },
        { label: "Type", key: "branch_type_name", value: "" },
        { label: "ผู้ส่ง", key: "action_by", value: "" },
        { label: "วันที่ส่งออก", key: "action_date", value: "" },
      ],
      destinationBranch: [
        {
          label: "Branch Name",
          key: "branch_name",
          value: "",
        },
        {
          label: "Stock",
          key: "destination_branch_stock_name",
          value: "",
        },
        { label: "Code", key: "code", value: "" },
        { label: "Type", key: "branch_type_name", value: "" },
        { label: "ผู้ส่ง", key: "action_by", value: "" },
        { label: "วันที่ส่งออก", key: "action_date", value: "" },
      ],
      form: {
        inventory_transfer_status_id: 0,
        inventory_transfer_id: 0,
        inventory_transfer_document_id: null,
        source_branch_id: null,
        destination_branch_id: null,
        isDraft: false,
        remark: "",
        status_history: [],
        destination_branch: {
          branch_id: 0,
          branch_type: 0,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          destination_branch_stock_name: "",
        },
        source_branch: {
          branch_id: 0,
          branch_type: 1,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          source_branch_stock_name: "",
        },
        cancellable: true,
        products: [],
        source_branch_stock: 0,
        destination_branch_stock: 0,
        source_branch_stock_name: "",
        destination_branch_stock_name: "",
        created_time: "",
      },
      fields: [
        { label: "#", key: "index", thStyle: { width: "10%" } },
        { label: "Image", key: "product_image", thStyle: { width: "10%" } },
        { label: "Bracode", key: "barcode", thStyle: { width: "10%" } },
        { label: "Name", key: "product_name", thStyle: { width: "10%" } },

        // { label: "Model Code", key: "model_Code", thStyle: { width: "20%" } },
        {
          label: "Serial No.",
          key: "serial",
          thStyle: { width: "10%" },
        },
        {
          label: "Quantity",
          key: "quantity",
          thStyle: { width: "10%" },
        },
        // { label: "Wgt", key: "weight", thStyle: { width: "10%" } },
        // { label: "Total Price", key: "total_price", thStyle: { width: "10%" } },
        // {
        //   label: "Price",
        //   key: "price",
        //   thStyle: { width: "10%" },
        // },
        // {
        //   label: "Note",
        //   key: "note",
        //   thStyle: { width: "10%" },
        // },

        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      allItem: [],
      filteredItems: [],
      searchDisplayProduct: "",
      isBusy: false,
      branchList: [],
      branchList2: [],
    };
  },
  validations: {
    form: {
      inventory_transfer_document_id: { required },
      destination_branch_id: { required },
      source_branch_id: { required },
      products: { required },
    },
  },
  async created() {
    this.isLoading = true;

    if (this.id > 0) {
      await this.getDetail();
    }
    await this.getTransferForm();
    this.form.created_time = this.form.created_time
      ? this.$moment(this.form.created_time).format("DD/MM/YYYY (hh:mm)")
      : this.$moment().format("DD/MM/YYYY (hh:mm)");
    this.isLoading = false;
  },
  methods: {
    async getDetail() {
      this.isBusy = true;
      const response = await this.axios(
        `/InventoryTransfer/Transfer/${this.id}`
      );
      this.isLoading = false;
      this.form = response.data.detail;
      this.items = response.data.detail.products;
      await this.handleSelectDocument(this.form.inventory_transfer_document_id);

      this.form.products = [...this.items];
      this.allItem = this.items;
      this.filteredItems = [...this.allItem];
      this.rows = this.allItem.length;
      this.updateDisplayedItems();
      const updateBranchValues = (branchArray, sourceData, stockKey) => {
        return branchArray.map((item) => ({
          ...item,
          value:
            item.key === "action_date"
              ? sourceData[item.key]
                ? this.$moment(sourceData[item.key]).format(
                    "DD/MM/YYYY (hh:mm)"
                  )
                : "-"
              : item.key === stockKey
              ? response.data.detail[stockKey] || ""
              : sourceData[item.key] || "",
        }));
      };

      this.sourceBranch = updateBranchValues(
        this.sourceBranch,
        response.data.detail.source_branch,
        "source_branch_stock_name"
      );
      this.destinationBranch = updateBranchValues(
        this.destinationBranch,
        response.data.detail.destination_branch,
        "destination_branch_stock_name"
      );
      document.getElementById("source-branch-list").value =
        response.data.detail.source_branch.branch_name;

      document.getElementById("Destination-branch-list").value =
        response.data.detail.destination_branch.branch_name;
      this.isLoading = false;
      this.isBusy = false;
    },

    async handleSelectDocument(val) {
      let res = await this.axios(`/InventoryTransfer/Document/${val}`);
      const { detail } = res.data;

      const createBranchList = (branches, stockKey) =>
        branches.map((x) => ({
          ...x,
          [stockKey]: detail[stockKey],
          id: x.branch_id,
          name: x.branch_name,
        }));

      this.branchList = createBranchList(
        detail.source_branches,
        "source_branch_stock_name"
      );
      this.branchList2 = createBranchList(
        detail.destination_branches,
        "destination_branch_stock_name"
      );

      if (this.form.inventory_transfer_status_id <= 1) {
        this.form = {
          ...this.form,
          source_branch_stock: detail.source_branch_stock,
          source_branch_stock_name: detail.source_branch_stock_name,
          destination_branch_stock: detail.destination_branch_stock,
          destination_branch_stock_name: detail.destination_branch_stock_name,
        };

        ["Destination-branch-list", "source-branch-list"].forEach((id) => {
          document.getElementById(id).value = "";
        });

        this.form.source_branch_id = null;
        this.form.destination_branch_id = null;
      }

      const createBranchData = (stockKey) => [
        { label: "Branch Name", key: "branch_name", value: "" },
        { label: "Stock", key: stockKey, value: "" },
        { label: "Code", key: "code", value: "" },
        { label: "Type", key: "branch_type_name", value: "" },
        { label: "ผู้ส่ง", key: "action_by", value: "" },
        { label: "วันที่ส่งออก", key: "action_date", value: "" },
      ];

      this.sourceBranch = createBranchData("source_branch_stock_name");
      this.destinationBranch = createBranchData(
        "destination_branch_stock_name"
      );

      this.form.inventory_transfer_document_id = val;
    },
    async getTransferForm() {
      const res = await this.axios(`/InventoryTransfer/Document/List/0`);
      this.transferFormList = res.data.detail;
    },
    handleSelectBranch(val, type) {
      if (!type) {
        const selectedBranch = this.branchList.find(
          (x) => x.branch_id.toString() === val
        );

        if (selectedBranch) {
          this.form.source_branch = selectedBranch;
          this.sourceBranch = this.sourceBranch.map((item) => ({
            ...item,
            value: selectedBranch[item.key] || "",
          }));
        }
        this.form.source_branch_id = val;
      } else {
        const selectedBranch = this.branchList2.find(
          (x) => x.branch_id.toString() === val
        );
        if (selectedBranch) {
          this.form.destination_branch = selectedBranch;
          this.destinationBranch = this.destinationBranch.map((item) => ({
            ...item,
            value: selectedBranch[item.key] || "",
          }));
        }
        this.form.destination_branch_id = val;
      }
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(true);
    },
    handleSelectProduct(items) {
      if (items) {
        this.allItem = items;
      }

      this.isBusy = true;
      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = items.length;
      this.form.products = items;
      this.filteredItems = items;

      this.isBusy = false;
    },
    handleProductQuantity(isPlus, item) {
      if (isPlus) {
        item.quantity += 1;
      } else if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        return;
      }
    },
    handleProductQuantityInput(event, item) {
      if (!item.quantity) {
        item.quantity = 1;
        return;
      }
    },
    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
    async deleteProduct(deleteId) {
      this.allItem = this.allItem.filter((x) => x.product_id !== deleteId);
      this.filteredItems = this.filteredItems.filter(
        (x) => x.product_id !== deleteId
      );
      this.form.products = this.form.products.filter(
        (x) => x.product_id !== deleteId
      );
      this.filter.page = 1;
      console.log(this.form.products);
      this.updateDisplayedItems();
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    async checkForm(isDraft) {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.form.isDraft = isDraft;
      this.isLoading = true;
      const res = await this.axios.post("/InventoryTransfer/Transfer/Save", this.form);
      if (res.data.result === 1) {
        this.successAlert();
        this.$router.push(`/inventory-transfer`);
      } else {
        this.errorAlert(res.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  color: #333 !important;
  font-size: 16x !important;
  font-weight: bold !important;
  position: relative;
}

::v-deep .multiselect__select::before {
  display: none;
}

::v-deep .multiselect__single {
  background-color: white !important;
}

.icon-container {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}

.custom-icon {
  color: white !important;
  font-size: 18px;
}

::v-deep .arrow {
  top: 30% !important;
}

.text-label {
  width: 100px;
}
.f-bold {
  font-weight: bold;
}

.text-light-gray {
  color: #9b9b9b;
}

textarea[disabled] {
  background-color: whitesmoke !important;
}

.input-quantity ::v-deep input {
  text-align: center !important;
}
</style>
