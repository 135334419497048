<template>
  <div>
    <b-modal
      v-model="modalShow"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>Select Item</template>
      <b-input-group class="mt-2">
        <b-form-input
          placeholder="Search Product Name"
          v-model.trim="filter.search"
          @keyup.enter="handleSearch"
        ></b-form-input>
        <b-input-group-append>
          <b-input-group-text>
            <b-iconstack
              font-scale="2"
              type="submit"
              @click.prevent="handleSearch"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        class="mt-2"
        empty-text="No matching records found"
      >
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(product_image)="data">
          <div class="position-relative picture-text pic-table">
            <div
              v-if="data.item.product_image"
              class="pic-box"
              v-bind:style="{
                'background-image': 'url(' + data.item.product_image + ')',
              }"
            ></div>
            <div v-else>
              <img
                class="pic-box pt-0"
                :src="default_image"
                alt="default Image"
                srcset=""
              />
            </div>
          </div>
        </template>
        <template v-slot:cell(status)="{ item }">
          <!-- <div :class="item.status == '1' ? 'text-success' : 'text-error'">
                {{ item.status ? "Active" : "Inactive" }}
              </div> -->
        </template>
        <template v-slot:cell(price)="{ item }">
          {{ item.price | numeral("0,0.00") }}
        </template>
        <template v-slot:cell(select)="{ item, index }">
          <b-form-group class="cb-table d-flex justify-content-center m-0">
            <b-form-checkbox
              :value="item.product_id"
              :checked="selectedProduct"
              @change="selectProductTable(item)"
            ></b-form-checkbox> </b-form-group
        ></template>
      </b-table>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :hideTake="true"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>

          <b-button
            class="submit-btn text-right"
            @click.prevent="submitSelectProduct"
            >Select Items</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    selectedItem: {
      type: Array,
    },
    document_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modalShow: false,
      isBusy: false,
      fields: [
        {
          key: "select",
          label: "",
          thClass: "w-1",
        },
        {
          key: "product_image",
          label: "Image",
          thClass: "w-2",
        },
        {
          key: "display_name",
          label: "Name",
          thClass: "w-2",
        },
        {
          key: "barcode",
          label: "Barcode",
          thClass: "w-2",
        },
        {
          key: "article_no",
          label: "Article No.",
          thClass: "w-2",
        },
        {
          key: "price",
          label: "Price",
          thClass: "w-2",
        },
      ],
      showing: 1,
      showingTo: 5,
      filter: {
        search: "",
        inventory_transfer_document_id: "0",
        branch: 0,
        inventory_transfer_status: [0],
        page: 1,
        take: 5,
      },

      rows: 0,
      items: [],
      selectedProduct: [],
      formProduct: [],
      isLoading: false,
      mapProduct: [],
    };
  },
  methods: {
    async show() {
      this.modalShow = true;
      this.getProduct();
      console.log(this.selectedItem);
      this.selectedProduct = this.selectedItem.map((x) => x.product_id);
      console.log(this.selectedProduct);
      this.formProduct = [...this.selectedItem];
    },
    async getProduct() {
      this.filter.inventory_transfer_document_id = this.document_id;
      this.isBusy = true;
      const res = await this.axios.post(
        `/InventoryTransfer/Transfer/SearchProduct`,
        this.filter
      );

      this.rows = res.data.detail.total_count;
      this.items = res.data.detail.search_product;

      this.isBusy = false;
    },
    pagination(value) {
      this.filter.page = value;
      this.getProduct();
    },
    handleChangeTake() {
      this.getProduct();
    },
    handleSearch() {
      this.filter.pageNo = 1;
      this.getProduct();
    },
    selectProductTable(product) {
      const index = this.selectedProduct.findIndex(
        (item) => item === product.product_id
      );
      if (index !== -1) {
        this.selectedProduct.splice(index, 1);
        this.formProduct.splice(index, 1);
      } else {
        this.selectedProduct.push(product.product_id);
        this.formProduct.push({ ...product, quantity: 1 });
      }
    },

    async submitSelectProduct() {
      this.filter = {
        search: "",
        inventory_transfer_document_id: "0",
        branch: 0,
        inventory_transfer_status: [0],
        page: 1,
        take: 5,
      };
      this.modalShow = false;
      this.$emit("success", this.formProduct);
    },
    hide() {
      this.filter = {
        search: "",
        inventory_transfer_document_id: "0",
        branch: 0,
        inventory_transfer_status: [0],
        page: 1,
        take: 5,
      };
      this.modalShow = false;
    },
  },
};
</script>
<style lang="scss">
.pic-box {
  margin: auto !important;
  width: 50%;
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
